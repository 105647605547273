import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const AudienceAnalytics = () => {
    return (
      <Layout>
        <SEO title="Audience Analytics" />
        <div className="flex flex-col px-16 py-32">
          <div className="w-full">
            <div className="flex flex-col items-center justify-center py-24 px-6 lg:px-16 case_study_bg text-white lg:h-96">
              <h1 className="text-3xl lg:text-6xl font-bold text-center">
                Audience Analytics
              </h1>
              <p className="lg:w-100 text-xl font-thin pt-8"></p>
            </div>
          </div>

          <div className="flex flex-row pt-8">
            <div className=" w-full lg:w-1/2 px-16 py-8 ">
              <StaticImage
                placeholder="blurred"
                src="../images/audience.png"
                alt="Audience Analytics - Offline and Online - Media Seal WPI"
              />
            </div>
            <article className=" py-8 w-full lg:w-1/2">
              <p className="pb-4">
                OOH Planning, Buying, and Measurement have remained static and
                inefficient. Connected consumers are always on the move. They
                Spend More than 70% of the Time Outside Their Home.
              </p>
              <p className="pb-1">Commuting</p>
              <p className="pb-1">Working</p>
              <p className="pb-4">Shopping </p>
              <p className="pb-2">
                They also Make 90%+ of Purchases “Offline”. However, offline
                media and activation planning is not built to address this
                dynamic movement. Merging offline and online consumer behaviour
                used to be almost impossible, but with the growth of mobile
                devices and recent technologies available to us, this gap has
                been bridged.{" "}
              </p>
              <p className="pb-2">
                The words “digital”, “programmatic”, “data”, etc now apply to
                offline media as much as online. This addresses the challenges
                posed when planning and executing multi-format OOH campaigns.{" "}
              </p>
              <p className="pb-1 font-semibold pt-6">What is possible? </p>
              <p className="pb-4">
                Faster Planning: <br />
                Going from brief to optimized proposals within minutes has
                become possible, custom audiences can be created and benchmarked
                against audience forecast.
              </p>

              <p className="pb-4">
                Programmatic Delivery: <br />
                Campaigns can be run in real-time using built-for-OOH DSP
                Measurement and Verification tools.
              </p>
              <p className="pb-4">
                Finally, in-flight OOH campaign performance analytics,
                attribution, and play-out validation come as standard.
              </p>
            </article>
          </div>
        </div>
      </Layout>
    )
}

export default AudienceAnalytics
